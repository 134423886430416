import React from "react"
import Layout from "../layout/layout"
import Hero from "../components/hero/TwoColumnWithPrimaryBackground"
import Features from "../components/features/Features"
import Download from "../components/download/Download"
export default function Home() {
  return (
    <Layout>
      <Hero />
      <Features />
      <Download />
    </Layout>
  )
}
